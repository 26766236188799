<template>
    <div class="payPage">
        <!-- 支付跳转中间页... -->
    </div>
</template>

<script>
    import WechatSdk from "weixin-js-sdk"
    export default {
        data() {
            return {
               orderIdOrSN:''
            }
        },

        created() {
            if(this.$route.params.orderIdOrSN) {
              this.orderIdOrSN = this.$route.params.orderIdOrSN
              
              // 保存订单到store，在支付结果页面请求使用
              this.$store.commit('setOrderIdOrSN', this.orderIdOrSN)
              setTimeout(() => {
                // console.log('订单号--支付页面', this.orderIdOrSN)
              }, 3000);
              
              this.getPayParams()
            } else {
              setTimeout(() => {
                // console.log('路由传参错误--支付页面', this.$route)
              }, 3000);
              this.$router.back()
            }
            
        },

        methods: {
            // 初始化sdk
            getJsapiTicket () {
             this.$request ({
                url: '/paltform/wx/mp/auth/getJsapiTicket',
                data: {
                    appid: 'wx556055e319bba389',
                    url: window.location.href
                  }
              }).then( res => {
                  // console.log('准备进行WechatSdk.config配置', res)
                  WechatSdk.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: res.result.appId, // 必填，公众号的唯一标识
                    timestamp: res.result.timestamp, // 必填，生成签名的时间戳
                    nonceStr: res.result.nonceStr, // 必填，生成签名的随机串
                    signature: res.result.signature,// 必填，签名
                    jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表
                  });
                })
            },      
            
            getPayParams () {
              this.$request ({
                url: '/reservation/getPayParams',
                data: {
                    orderIdOrSN: this.orderIdOrSN,
                    payType: 'WXPAY',
                    tradeType: 'JSAPI'
                  }
               }).then( (res) => {
                    this.getJsapiTicket()
                    var _that = this
                    // console.log('即将调起微信支付', res)
                    WechatSdk.ready(function(){
                        WechatSdk.chooseWXPay({
                            timestamp: res.result.timeStamp,
                            nonceStr: res.result.nonceStr,
                            package: res.result.packageValue,
                            signType: res.result.signType,
                            paySign: res.result.paySign,
                            success: (res) => {
                                setTimeout(() => {
                                    _that.$toast('支付成功')
                                    _that.$store.commit('setPayResult', true)
                                    _that.$router.push( '/payment-results' )
                                }, 500);
                            },
                            fail: (error) => setTimeout(() => {
                                _that.$toast('支付失败')
                                 _that.$store.commit('setPayResult', false)
                                _that.$router.push('/payment-results')
                            }, 500),
                            
                            cancel: ( cancel ) => setTimeout(() => {
                                _that.$toast('支付取消')
                                _that.$store.commit('setPayResult', false)
                                _that.$router.push('/payment-results')
                            }, 500)
                      })
                  });
                        
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .payPage{
        padding: .15rem;
        font-size: .2rem;
        text-align: center;
    }
</style>